// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
    _6: ImageType,
    _7: ImageType,
    _8: ImageType,
    _9: ImageType,
  },
};

class Blog_2016_09_16_Hidden extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="3AIII" title="Hidden" />
        <p>
          After a fun day leading Behunin, we got invited to do Hidden. I was
          pretty sick at the time and didn't want to do anything too difficult,
          so Hidden it was.
        </p>
        <p>
          Hidden is certainly not the most spectacular Zion canyon, but does
          have some fun <GlossaryLink>down climbs</GlossaryLink>, cave-like
          rappels, and a great view from the top.
        </p>
        <Image caption="View from the top" image={data._1} />
        <Image caption="Me at the top of the first rappel" image={data._2} />
        <Image
          caption="Lots of down climbs at the beginning of the technical section"
          image={data._3}
        />
        <p>
          The cave sections are from huge boulders that sluff off the cliffs and
          get lodged high above the ground. So we down climb to the massive{' '}
          <GlossaryLink>chockstone</GlossaryLink>, rappel through the hole off
          the <GlossaryLink>up canyon</GlossaryLink> side, walk under the
          chockstone, and come back out.
        </p>
        <Image caption="Looking up through a rappel hole" image={data._4} />
        <Image caption="Dave on rappel" image={data._5} />
        <Image caption="Me on rappel" image={data._6} />
        <Image image={data._7} />
        <p>
          Midway through we stopped for lunch. This was the first time I tried
          chips, beans, and salsa for lunch in a canyon. Absolutely hooked. I
          highly recommend it.
        </p>
        <Image caption="Lunch time" image={data._8} />
        <p>
          After a few more rappels we started noticing signs of non-canyoneers,
          as in someone carved "Not all who wander are lost" into the wall. That
          was a signal to us that the solitary experience would soon be over.
        </p>
        <Image
          caption="Done with rappels and hiking out the non-technical section"
          image={data._9}
        />
        <p>
          More travel brought more people and we soon reached Zion Canyon, the
          paved switchback, and buses. A fine day in a fine canyon with fine
          canyoneers.
        </p>
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2016-09-16-hidden')(Blog_2016_09_16_Hidden);

export const query = graphql`
  query {
    _1: file(relativePath: { eq: "images/blog/2016-09-16-hidden/1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(relativePath: { eq: "images/blog/2016-09-16-hidden/2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(relativePath: { eq: "images/blog/2016-09-16-hidden/3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(relativePath: { eq: "images/blog/2016-09-16-hidden/4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(relativePath: { eq: "images/blog/2016-09-16-hidden/5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _6: file(relativePath: { eq: "images/blog/2016-09-16-hidden/6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _7: file(relativePath: { eq: "images/blog/2016-09-16-hidden/7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _8: file(relativePath: { eq: "images/blog/2016-09-16-hidden/8.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _9: file(relativePath: { eq: "images/blog/2016-09-16-hidden/9.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
